import mainProvider from "../providers/MainProvider";
import * as React from "react";

export const toTitleCase = (str) => str.replace('_', ' ').replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
);
export const requestNormalizer = (value) => {
    if (value.ids) return {"ids": JSON.stringify(
        value.ids.split(",").map(
            function(item) {
                return parseInt(item);}
            )
        )
    };
    else if (value.email) return {"email": value.email};
    else return {"ids": JSON.stringify([]), "email": ""}
};

export const getProviders = (config) => {
    const list = [];
    for (const element of config.PROJECTS_CONFIG) {
        let provider = new mainProvider(element.name);
        list.push(
            {
                dataProvider: provider, resources: [provider.resources]
            }
      )
    }
    return list
}
